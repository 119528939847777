import './Map.css';
import { LayersControl, Map, TileLayer, WMSTileLayer } from 'react-leaflet';
import LocateControl from './mapUtils/locateControl';
import Search from 'react-leaflet-search'
import { LatLng } from 'leaflet';

const locateOptions = {
  position: 'topleft'
}

function MeterMap() {
  return (
    <Map zoom={13} center={[34.952, -89.973]} maxZoom={21} minZoom={11} >

      <LocateControl options={locateOptions} startDirectly={false} />
      <Search
        position='topleft'
        inputPlaceholder='Search Location'
        showMarker={false}
        zoom={19}
        openSearchOnLoad={false}
        mapStateModifier='flyTo'
        providerOptions={{ searchBounds: [new LatLng(34.833151, -90.219727), new LatLng(35.044234, -89.692383)] }}
      />

      <LayersControl collapsed={false}>
        <LayersControl.Overlay name="Meters">
          <WMSTileLayer
            url='https://ramnode.civil-link.com/geoserver/Ramnode/gwc/service?'
            layers='SouthavenMeters'
            format='image/png'
            transparent={true}
            zIndex={11}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Water Lines + Hydrants">
          <WMSTileLayer
            url='https://ramnode.civil-link.com/geoserver/Ramnode/gwc/service?'
            layers='AMRref'
            format='image/png'
            transparent={true}
            zIndex={10}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Address Numbers" checked={true}>
          <WMSTileLayer
            url='https://ramnode.civil-link.com/geoserver/Ramnode/gwc/service?'
            layers='address_numbers_amr'
            format='image/png'
            transparent={true}
            minZoom={17}
            zIndex={9}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Street Names" checked={true}>
          <TileLayer
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}{r}.{ext}"
            ext='png'
            zIndex={2}
          />
        </LayersControl.Overlay>
      </LayersControl>

      <TileLayer
        url="https://maps.desotocountyms.gov/ArcGIS/rest/services/Photos2019/MapServer/tile/{z}/{y}/{x}"
        opacity={1}
        maxZoom={21}
        maxNativeZoom={21}
        zIndex={1}
      />

      {/* <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        opacity={1}
        maxZoom={21}
        maxNativeZoom={21}
        zIndex={0}
      /> */}
    </Map>
  )
}

export default MeterMap;
